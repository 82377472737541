// Toastr options
toastr.options = {
  "closeButton": true,
  "progressBar": true
  // "timeOut": "50000"
  // "extendedTimeOut": "50000"
};

$.cart_update_a = function() {
  $('table.radios tr').unbind('click');
  return $('table.radios tr').click(function(e) {
    $(this).find('input[type="radio"]').prop("checked", true);
    if ($(this).data('personal') === true) {
      $('tr[data-cash="cod"]').each(function() {
        $(this).addClass('disabled');
        $(this).find('input[type="radio"]').prop("checked", false);
        return $(this).hide();
      });
      $('tr[data-cash="personal"]').each(function() {
        $(this).removeClass('disabled');
        $(this).find('input[type="radio"]').prop("checked", false);
        return $(this).show();
      });
    } else {
      if ($(this).data('personal') === false) {
        $('tr[data-cash="personal"]').each(function() {
          $(this).addClass('disabled');
          $(this).find('input[type="radio"]').prop("checked", false);
          return $(this).hide();
        });
        $('tr[data-cash="cod"]').each(function() {
          $(this).removeClass('disabled');
          $(this).find('input[type="radio"]').prop("checked", false);
          return $(this).show();
        });
      }
    }

    $(this).closest('form').find('#cart_update_shipping').val('1');
    return Rails.fire($(this).closest('form')[0], 'submit')
  });
};

// Magnific
jQuery.extend(true, $.magnificPopup.defaults, {
  tClose: "Zavřít (Esc)", // Alt text on close button
  tLoading: "Načítám...", // Text that is displayed during loading. Can contain %curr% and %total% keys
  gallery: {
    tPrev: "Předchozí (Levá klávesová šipka)", // Alt text on left arrow
    tNext: "Další (Pravá klávesová křivka)", // Alt text on right arrow
    tCounter: "%curr% / %total%"
  }, // Markup for "1 of 7" counter

  image: {
    tError: "<a href=\"%url%\">Obrázek</a> se nepodařilo načíst."
  }, // Error message when image could not be loaded

  ajax: {
    tError: "<a href=\"%url%\">Obsah</a> se nepodařilo načíst"
  }, // Error message when ajax request failed

  callbacks: {
    open() {},
      // if $('.white-popup-block').length
    close() {}
  }
});

$.magnify = function() {
  $('.slider_project a, .decors a, .magnific').magnificPopup({
    type: 'image',
    gallery: {
      enabled: true
    }
  });

  $('.popup').magnificPopup({
    type: 'inline'});

  $('.group .doors').each(function() {
    return $(this).find('a.door').magnificPopup({
      type: 'image',
      gallery: {
        enabled: true
      }
    });
  });

  $('.ajax-popup').click(function() {
    if ($.magnificPopup.instance.isOpen) { return $.magnificPopup.close(); }
  });

  return $('.ajax-popup').magnificPopup({
    type: 'ajax',
    callbacks: {
      beforeOpen() {
        if ($.magnificPopup.instance.isOpen) { return $.magnificPopup.close(); }
      },
      parseAjax(mfpResponse) {
        return mfpResponse.data = $(mfpResponse.data).find('#yield').addClass("white-popup-block " + window.popsize);
      },
        // NProgress.configure({ parent: '.white-popup-block' })
      ajaxContentAdded() {
        return $.refresh_methods();
      }
    }
  });
};

$.refresh_methods = function() {

  $('.close-popup').unbind('click');
  return $('.close-popup').click(function(e) {
    e.preventDefault();
    return $.magnificPopup.instance.close();
  });
};

const js = function() {
  // Magnify
  $.magnify();

  // Cart items
  $('tr.item input.amount').bind("change", function() {
    return Rails.fire($(this).closest('form')[0], 'submit')
  });
  $('tr.item td.amount i').click(function(e) {
    e.preventDefault();
    const input = $(this).closest('td.amount').find('input.amount');
    if ($(this).hasClass('plus')) {
      input.val(parseInt(input.val()) + 1);
      return Rails.fire($(this).closest('form')[0], 'submit')
    } else {
      if (parseInt(input.val()) <= 1) {
        return $(this).closest('tr.item').find('td.remove a').trigger('click');
      } else {
        input.val(parseInt(input.val()) - 1);
        return Rails.fire($(this).closest('form')[0], 'submit')
      }
    }
  });

  // Tooltips
  // $('td.hint').tooltip({
  //   container: 'body'
  // })

  $.cart_update_a();

  $('tr[data-hide="true"]').hide();

  // Delivery address
  const checkbox_address_same = $('.cart_address_same input[type="checkbox"]');
  if (checkbox_address_same.length) {
    if (checkbox_address_same.prop('checked')) {
      $('.delivery_address_wrapper').hide();
    }
    checkbox_address_same.change(function() {
      if ($(this).prop('checked')) {
        return $('.delivery_address_wrapper').hide();
      } else {
        return $('.delivery_address_wrapper').show();
      }
    });
  }

  // Create account
  const checkbox_create_account = $('.cart_create_account input[type="checkbox"]');
  if (checkbox_create_account.length) {
    $('.row.password').hide();
    if (checkbox_create_account.prop('checked')) {
      $('.row.password').show();
    }
    checkbox_create_account.change(function() {
      if ($(this).prop('checked')) {
        return $('.row.password').show();
      } else {
        return $('.row.password').hide();
      }
    });
  }

  // Toastr
  if ($('body').data('notice')) {
    toastr.info($('body').data('notice'));
  }
  if ($('body').data('alert')) {
    toastr.warning($('body').data('alert'));
  }

  // Categories list - subcategories
  $('ul.subcategory').hide();
  $('ul.subcategory').parent().addClass('parent');
  const more_html = '<a href="#" class="fa fa-angle-down more"></a>';
  $('ul.categories_list li.parent').each(function() {
    return $(this).children('a').after(more_html);
  });
  $('ul.categories_list a.more').click(function(e) {
    e.preventDefault();
    if ($(this).hasClass('fa-angle-down')) {
      $(this).parent().children('ul.subcategory').slideDown(200);
      return $(this).removeClass('fa-angle-down').addClass('fa-angle-up');
    } else {
     $(this).parent().children('ul.subcategory').slideUp(200);
     return $(this).removeClass('fa-angle-up').addClass('fa-angle-down');
   }
  });

  return $('ul.categories_list li.active.parent').each(function() {
    $(this).children('ul.subcategory').show();
    return $(this).children('a.more').removeClass('fa-angle-down').addClass('fa-angle-up');
  });
};

if (Turbolinks.supported) {
  document.addEventListener("turbolinks:load", () => {
    js()
  })
} else {
  $(document).ready(js);
}
