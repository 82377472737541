// CSS
import 'stylesheets/application'

// JS
Rails.start()
Turbolinks.start()

import 'popper.js'
import 'bootstrap'
import 'application/script_application'

let forceCompile = true
